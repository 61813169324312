import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import GetDiscountTitles from './GetDiscountTitles';
import styles from './DiscountsInfo.module.css';

const DiscountInfo = ({ discountsList, discount }) => {
  return (
    <span className={styles['discount-info']}>
      <Tooltip title={<GetDiscountTitles discountsList={discountsList} />}>
        <InfoIcon
          style={{ color: '#ea9449', fontSize: '18px', cursor: 'pointer' }}
        />
      </Tooltip>
      NOK {discount.toFixed(2)}
    </span>
  );
};

export default DiscountInfo;
