import React, { useEffect, useState } from 'react';
import styles from './InviteRelativesForm.module.css';
import { Avatar, makeStyles, TextField } from '@material-ui/core';
import DiscardModal from '../DiscardPopupModal/DiscardModal';
import SavedPopupModal from '../SavedPopupModal/SavedPopupModal';
import { request, fetch_request } from '../../../../service/request';
import { useDispatch } from 'react-redux';
import DeleteIcon from '../../../../img/DeleteRedIcon.svg';
import EditIcon from '../../../../img/EditSmallIcon.svg';
import ConformModal from '../../../NewSettings/atoms/ConformDeleteModal';
import PhoneNumber from './PhoneNumberInput/PhoneNumber';
import Autocomplete from '@material-ui/lab/Autocomplete';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import { ProductKeys } from '../../../../service/constants';

const useStyle = makeStyles((theme) => ({
  rootIconMenu: {
    color: '#9f9f9f !important',
  },
  rootIconMenuOpen: {
    display: 'none',
  },
  selector: {
    width: '100%',
    background: '#F2F5F8 !important',
    borderRadius: '8px',
    padding: '18px 28px',
    border: '1px solid transparent',
    '&:focus': {
      outline: 'none',
      border: '1px solid',
    },
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  rootMembers: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootMembersClicked: {
    width: '191px',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: '1px solid #FEA65A',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#36434B',
    textTransform: 'none',
    '@media (max-width: 750px)': {
      width: '167px',
      height: '40px',
    },
  },
  rootConfirm: {
    width: '171px',
    height: '37px',
    borderRadius: '10px',
    backgroundColor: '#FEA65A',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FEA65A',
    },
  },
  input: {
    padding: '10px !important',
  },
}));

const InviteRelativesForm = ({
  graveInvitation,
  graveInvitations,
  invitationId,
  grave,
  handelCloseForm,
  disable,
  handelInvitationId,
  handelGraveInvitations,
  saveGraveInvitationsRedux,
  handleTabChange,
  countriesList,
  relationList,
  directionsList,
  currentSubscription,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [openGraveConnectionModal, setOpenGraveConnectionModal] =
    useState(false);
  const classes = useStyle();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [selectedReciprocal, setSelectedReciprocal] = useState('');
  const [selectedRelation, setSelectedRelation] = useState('');
  const [openInValidModal, setOpenInValidModal] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [leftToRightRelation, setLeftToRightRelation] = useState({});
  const [rightToLeftRelation, setRightToLeftRelation] = useState({});
  const [editRelation, setEditRelation] = useState({
    id: '',
    reciprocal_relation_id: '',
    grave_id: '',
    full_name: '',
    phone_number: '',
    country_id: 0,
    admin: false,
    relation_id: '',
  });

  useEffect(() => {
    if (!isEmpty(graveInvitation)) {
      setEditRelation({ ...graveInvitation });
      const relation = relationList.find(
        (relation) => relation.id === graveInvitation.relation_id
      );
      if (relation) {
        setLeftToRightRelation(relation);
      }
      const RcRelation = relationList.find(
        (relation) => relation.id === graveInvitation.reciprocal_relation_id
      );
      if (RcRelation) {
        setRightToLeftRelation(RcRelation);
      }
    } else if (countriesList.length > 0) {
      const country = countriesList.find(
        (country) => country.dial_code === '+47'
      );
      if (country) {
        setEditRelation({ ...graveInvitation, country_id: country.id });
      }
    }
  }, [graveInvitation]);

  const handelEditForm = (field, value) => {
    if (field === 'relation_id') {
      const relation = relationList.find((item) => item.id === value);
      setSelectedRelation(relation.name);
    }
    if (field === 'reciprocal_relation_id') {
      const relation = relationList.find((item) => item.id === value);
      setSelectedReciprocal(relation.name);
    }
    setEditRelation((prev) => ({
      ...prev,
      [field]: value,
    }));
    setIsChanged(true);
  };

  const handleLeftToRightRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setLeftToRightRelation({});
      handelEditForm('relation_id', '');
    } else if (reason === 'select-option') {
      setLeftToRightRelation(value);
      handelEditForm('relation_id', value.id);
    }
    setRightToLeftRelation({});
  };

  const handleRightToLeftRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setRightToLeftRelation({});
      handelEditForm('reciprocal_relation_id', '');
    } else if (reason === 'select-option') {
      setRightToLeftRelation(value);
      handelEditForm('reciprocal_relation_id', value.id);
    }
  };

  const deleteRelation = () => {
    if (editRelation.id) {
      request(`/grave_invited_users/${editRelation.id}`, null, 'delete')
        .then((res) => {
          const newArray = graveInvitations.filter(
            (relation) => relation.id !== editRelation.id
          );
          handelGraveInvitations(newArray);
          saveGraveInvitationsRedux(newArray);
          setOpenDeleteModal(false);
          showUserNotification('invitasjonen er slettet', 'success');
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
      setOpenDeleteModal(false);
    }
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
    setIsChanged(false);
    if (Object.keys(graveInvitation).length > 0) {
      setEditRelation({ ...graveInvitation });
      handelInvitationId(null);
    } else {
      clearRelation();
      handelCloseForm();
      onClose();
      handelInvitationId(null);
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const closeSaveModal = () => {
    setOpenSaveModal(false);
    handelCloseForm();
    handelInvitationId(null);
    handleTabChange();
  };

  const createNewRelative = async () => {
    if (invitationId) {
      const inviteRelation = {
        grave_invited_user: {
          full_name: editRelation.full_name,
          country_id: editRelation.country_id,
          phone_number: editRelation.phone_number,
          admin: editRelation.admin,
          relation_id: editRelation.relation_id,
          reciprocal_relation_id: editRelation.reciprocal_relation_id,
          grave_id: grave.id,
        },
      };
      request(`/grave_invited_users/${invitationId}`, inviteRelation, 'put')
        .then((res) => {
          setOpenSaveModal(true);
          saveGraveInvitationsRedux(
            graveInvitations.map((w) =>
              w.user_id === invitationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
          handelGraveInvitations(
            graveInvitations.map((w) =>
              w.user_id === invitationId
                ? {
                    ...res.data,
                  }
                : w
            )
          );
        })
        .catch((err) => {
          showUserNotification('noe gikk galt, prøv igjen', 'warning');
        });
    } else {
      const inviteRelation = {
        grave_invited_user: {
          full_name: editRelation.full_name,
          country_id: editRelation.country_id,
          phone_number: editRelation.phone_number,
          admin: editRelation.admin,
          relation_id: editRelation.relation_id,
          reciprocal_relation_id: editRelation.reciprocal_relation_id,
          grave_id: grave.id,
        },
      };
      request(`/grave_invited_users`, inviteRelation, 'post')
        .then((res) => {
          saveGraveInvitationsRedux([res.data, ...graveInvitations]);
          handelGraveInvitations([res.data, ...graveInvitations]);
          setOpenSaveModal(true);
          clearRelation();
        })
        .catch((error) => {
          setIsValidNumber(true);
          showUserNotification(
            error.response.data.error || error.message,
            'error'
          );
        });
    }
  };

  const clearRelation = () => {
    setEditRelation({
      id: '',
      reciprocal_relation_id: '',
      grave_id: '',
      full_name: '',
      phone_number: '',
      country_id: 0,
      admin: false,
      relation_id: '',
    });
  };

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 32) {
      return true;
    } else if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
      return false;
    } else if (
      [33, 36, 37, 38, 39, 64].includes(keyCode) ||
      (keyCode >= 35 && keyCode <= 38) ||
      (keyCode >= 42 && keyCode <= 44) ||
      (keyCode >= 46 && keyCode <= 47)
    ) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  return (
    <>
      {openGraveConnectionModal && (
        <UpgradePlanModal
          isOpen={openGraveConnectionModal}
          handleCloseUpgradePlansModal={() =>
            setOpenGraveConnectionModal(false)
          }
          body='Å invitere er tilgjengelig med Bevisst medlemskap.'
          title='Oppdater medlemskapet her'
        />
      )}
      <div>
        {!disable && (
          <div className={styles['grave-actions-container']}>
            <div
              className={styles['grave-information-edit-delete-container']}
              style={{ marginBottom: '14px' }}
            >
              <div
                className={styles['action-btn']}
                onClick={() => {
                  handelInvitationId(editRelation.id);
                  handelCloseForm();
                }}
              >
                <img src={EditIcon} alt='Edit' />
                <button type='button'>Redigere</button>
              </div>
              <div
                className={styles['action-btn']}
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                <img src={DeleteIcon} alt='Delete' />
                <button type='button'>Slett</button>
              </div>
            </div>
          </div>
        )}
        <div className={styles['company-name-container']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Navn</div>
            <input
              disabled={!disable}
              className={styles['inputs']}
              type='text'
              required={true}
              placeholder='Navn'
              value={editRelation?.full_name ? editRelation.full_name : ''}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                const inputValue = e.target.value;
                const trimmedValue = inputValue
                  .replace(/^\s+/, '')
                  .replace(/ {2,}$/, ' ');
                handelEditForm('full_name', trimmedValue);
              }}
            />
          </div>
          <div className={styles['input-element']}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className={styles['title']}>Telefonnummer</div>
            </div>
            <PhoneNumber
              disable={!disable}
              relation={editRelation}
              onNumberChange={handelEditForm}
              countriesList={countriesList}
            />
          </div>
        </div>
        <div className={styles['company-name-container']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>
              Gravlagtes relasjon til den du inviterer
            </div>
            <div className={styles['choose-relation']}>
              <Autocomplete
                id='relation-combo-box'
                options={relationList}
                classes={{ input: classes.input }}
                getOptionLabel={(option) => option.name || ''}
                style={{ width: '99%' }}
                value={leftToRightRelation}
                disabled={!disable}
                renderInput={(params) => (
                  <TextField placeholder='Velg relasjon' {...params} />
                )}
                onChange={handleLeftToRightRelation}
              />
            </div>
          </div>
          <div className={styles['input-element']}>
            <div className={styles['title']}>
              Den du inviterer sin relasjon til gravlagte
            </div>
            <div className={styles['choose-relation']}>
              <Autocomplete
                id='relation-combo-box'
                options={filterDirectionList(leftToRightRelation)}
                classes={{ input: classes.input }}
                getOptionLabel={(option) => option.name || ''}
                value={rightToLeftRelation}
                style={{ width: '99%' }}
                disabled={isEmpty(leftToRightRelation) || !disable}
                renderInput={(params) => (
                  <TextField placeholder='Velg relasjon' {...params} />
                )}
                onChange={handleRightToLeftRelation}
              />
            </div>
          </div>
        </div>
        <div className={styles['company-name-container']}>
          <div className={styles['input-element']}>
            <div className={styles['title']}>Administratortilgang</div>
            <select
              disabled={!disable}
              native
              value={editRelation?.admin ? editRelation.admin : ''}
              onChange={(e) => handelEditForm('admin', e.target.value)}
              className={styles['selector']}
            >
              <option value='' disabled>
                Velg rolle
              </option>
              <option value={true}>Administrator</option>
              <option value={false}>Normal</option>
            </select>
          </div>
        </div>
        {selectedRelation && selectedReciprocal && (
          <div className={styles['relation-message']}>
            {`${grave.name} er den inviterte sin ${selectedRelation} og den du inviterer er ${selectedReciprocal} til ${grave.name}`}
          </div>
        )}
        {disable && (
          <div className={styles['grave-btn-container']}>
            <div className={styles['grave-information_content-verify']}>
              <button
                type='button'
                className={styles['rootDiscard']}
                onClick={() => {
                  if (isChanged) {
                    setOpenDiscardModal(true);
                  } else {
                    closeDiscardModal();
                  }
                }}
              >
                Avbryt
              </button>
              <button
                className={styles['rootVerify']}
                type='submit'
                onClick={() => {
                  if (
                    currentSubscription &&
                    currentSubscription.product.key === ProductKeys.free
                  ) {
                    setOpenGraveConnectionModal(true);
                  } else {
                    createNewRelative();
                  }
                }}
              >
                Lagre
              </button>
            </div>
          </div>
        )}
      </div>
      {openDiscardModal && (
        <DiscardModal
          open={openDiscardModal}
          handleClose={(value) => setOpenDiscardModal(value)}
          onConfirm={closeDiscardModal}
          title='relative'
        />
      )}
      {openSaveModal && (
        <SavedPopupModal
          open={openSaveModal}
          titleKey='relation'
          handleClose={closeSaveModal}
        />
      )}
      {openDeleteModal && (
        <ConformModal
          title='relative'
          open={openDeleteModal}
          handleClose={closeDeleteModal}
          deletePayment={deleteRelation}
        />
      )}
    </>
  );
};

export default InviteRelativesForm;
